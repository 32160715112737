import './App.css';


function App() {

  return (
    <div
    className="App">
      <h1>RealFX-Code</h1>
      <p>
        I'm a person who does web development in my free
        time. Feel free to check out any
        repos that I have, They're public
        for a reason!
      </p>
    </div>
  );
}

export default App;
